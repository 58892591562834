export default class AzureSynthesizer {
  constructor(options) {
    this.language = options.language;
    this.voice = options.voice;
    this.mode = options.mode;
    this.text = options.text;
    var SpeechSDK = window.SpeechSDK;
    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
      "9d2d6a064406477ca1ef992c4a20872d",
      "westeurope"
    );
    //speechConfig.speechRecognitionLanguage = options.language;
    speechConfig.speechSynthesisLanguage = this.language;
    speechConfig.speechSynthesisVoiceName = this.voice;
    if (this.mode == 0) {
      const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
      this.synthesizer = new SpeechSDK.SpeechSynthesizer(
        speechConfig,
        audioConfig
      );
    } else {
      this.synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, null);
    }
  }

  async createFile() {
    let self = this;
    let promise = new Promise(function(resolve, reject) {
      self.synthesizer.speakTextAsync(
        self.text,
        result => {
          const audioData = result.audioData;
          self.synthesizer.close();
          resolve(audioData);
        },
        error => {
          alert("error");
          self.synthesizer.close();
          reject(error);
        }
      );
    });
    return promise;
  }

  async createFile2(text) {
    let self = this;
    /*await self.synthesizer.speakTextAsync(
      text,
      result => {
        console.log("1");
        const audioData = result.audioData;
        console.log("2");
        self.synthesizer.close();
        console.log("3");
        console.log(result);
        console.log("4");
        return audioData;
      },
      error => {
        console.log(error);
        self.synthesizer.close();
      }
    );*/
    self.synthesizer.speakTextAsync(
      text,
      result => {
        console.log("1");
        const audioData = result.audioData;
        console.log("2");
        self.synthesizer.close();
        console.log("3");
        console.log(result);
        console.log("4");
        return audioData;
      },
      error => {
        console.log(error);
        self.synthesizer.close();
      }
    );
    console.log("hola");
  }
}
